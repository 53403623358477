body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Bosk';
    src: local('Bosk'), url('/public/assets/fonts/Bosk.otf') format('truetype');
    /* Add other font weights/styles if needed */
  }

  @font-face {
    font-family: 'Knickerbockers';
    src: local('Knickerbockers'), url('/public/assets/fonts/Knickerbockers.otf') format('truetype');
    /* Add other font weights/styles if needed */
  }

  @font-face {
    font-family: 'Ramillas';
    src: local('Ramillas'), url('/public/assets/fonts/Ramillas.ttf') format('truetype');
    /* Add other font weights/styles if needed */
  }
  
  .hero {
    animation: fade-in 0.3s;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  .fade-in {
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  